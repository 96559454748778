import React from 'react';
import PropTypes from 'prop-types';


import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation
} from "react-router-dom";

import { createBrowserHistory } from "history";


import { LoginWindow } from '../../organisms/LoginWindow/LoginWindow';

import { Report } from '../../pages/Report/Report';

import { Reports } from '../../pages/Reports/Reports';

import { Landing } from '../../pages/Landing/Landing';


import { Header } from '../../organisms/Header/Header';

import './MenuView.scss';


// export const MenuView = ({logged_in}) => {

export class MenuView extends React.Component {

    constructor({id, logged_in}) {
        super();
        this.id = id;
        this.history = createBrowserHistory();
        this.logged_in = logged_in;

        this.not_logged_in_routes  = <Routes>
            <Route path="/" element={
                <div className="projectslist_area">
                    <Landing ref={instance => { this.child = instance; }}/>
                </div>
            }/>
            <Route path="/login" element={
                <div className="projectslist_area">
                    <LoginWindow ref={instance => { this.child = instance; }}/>
                </div>
            }/>
            <Route path="/login/:fast_access_token" element={
                <div className="projectslist_area">
                    <LoginWindow ref={instance => { this.child = instance; }}/>
                </div>
            }/>
        </Routes>

        this.logged_in_routes = <Routes>
            <Route path="/" element={
                <div className="projectslist_area">
                    <Reports ref={instance => { this.child = instance; }}/>
                </div>
            }/>
            <Route path="/report/:report_id" element={
                <div className="projectslist_area">
                    <Report ref={instance => { this.child = instance; }}/>
                </div>
            }/>
            <Route path="/login" element={
                <div className="projectslist_area">
                    <LoginWindow ref={instance => { this.child = instance; }}/>
                </div>
            }/>
            <Route path="/login/:fast_access_token" element={
                <div className="projectslist_area">
                    <LoginWindow ref={instance => { this.child = instance; }}/>
                </div>
            }/>
        </Routes>

    }

    

    render = () => {
        return(
            <div className="menu_view">
                <Router>
                    {/* <div className="header_area">
                        <Header locationChangeTrigger={this.locationChangeTrigger}/>
                    </div> */}
                    <div>
                        {
                            this.logged_in 
                            ? this.logged_in_routes
                            : this.not_logged_in_routes
                        }
                    </div>
                </Router>
            </div>
        );
    }
}
