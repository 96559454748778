import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import ReactDomServer from 'react-dom/server';


import {ScreenDrawer} from '../../molecules/ScreenDrawer/ScreenDrawer';
import {SidebarElement} from '../../atoms/SidebarElement/SidebarElement';

import './Report.scss';
import { none } from 'ol/centerconstraint';

import { metric } from '../../metric';

import { serverUrl, fetchModule } from '../../fetch';

import dropdown_icon from "../../assets/icons/dropdown_icon.svg";
import dropdown_icon_up from "../../assets/icons/dropdown_icon_up.svg";
import close_icon from "../../assets/icons/close_icon.svg";



export class Report extends React.Component {
    constructor(props = {}) {
        // open_edit_form_trigger={this.state.open_edit_form_trigger}
        super();
        console.log("props: ", props);
        this.not_use_header = props.not_use_header ? props.not_use_header : false;
        if (props.demo_id) {
            this.run_id = props.demo_id;
        } else {
            const myParam = window.location.pathname.replace("/report/", "");
            console.log("run_id: ", myParam);
            this.run_id = parseInt(myParam);    
        }
        this.info_to_render_placeholder = <p>Нажмите на изображение, чтобы получить данные</p>
        this.image_url_prefix = serverUrl + "/reports/image/"
        this.state = {
            is_mobile: false,
            top_close_bar_visible: false,
            bottom_close_bar_visible: false,
            info_to_render: this.info_to_render_placeholder,
            run_id: this.run_id,
            image_url:  "", //this.image_url_prefix + this.run_id + "/original.jpg",
            selected_index: 0,
            report_data: {},
            report_name_header: "Исходное изображение",
            left_side_bar: [
                {
                    "name": "Исходное изображение", //"Original screenshot",
                    "selected": true,
                    "id": 1,
                    "report_filer": "original_screenshot",
                    "report_image": "original.jpg",
                },
                { 
                    "name": "Распознавание текста", //"Text recognition",
                    "selected": false,
                    "id": 2,
                    "report_filer": "text_extracted",
                    "report_image": "text_extracted_boxes.jpg",
                },
                // { 
                //     "name": "Углублённое распознавание текста", //"Text recognition (deep)",
                //     "selected": false,
                //     "id": 3,
                //     "report_filer": "text_extracted_extended",
                //     "report_image": "text_extracted_extended_boxes.jpg",
                // },
                // { 
                //     "name": "Text color validation",
                //     "selected": false,
                //     "id": 4,
                //     "report_filer": "text_color_validation",
                // },
                // { 
                //     "name": "Color scheme",
                //     "selected": false,
                //     "id": 5,
                //     "report_filer": "color_scheme",
                // },
                // { 
                //     "name": "Positioning and framing",
                //     "selected": false,
                //     "id": 6,
                //     "report_filer": "positioning_and_framing",
                // },
                { 
                    "name": "Карта внимания пользователя", // "User attention",
                    "selected": false,
                    "id": 7,
                    "report_filer": "user_attention",
                    "report_image": "text_user_attention.jpg",
                },

            ]
        }
    }

    componentDidMount = () => {
        this.get_report(1, 0);
        this.check_mobile();
    }

    componentWillUnmount = () => {
      // console.log("do you whant to leave?")
      // alert("do you whant to leave?");
    }

    check_mobile = () => {
        if (window.screen.width <= 800){
            this.setState(state => ({
                ...state, 
                is_mobile: true
            }));
        }
    }


    onLeftSideBarClick = (id) => {
        let left_side_bar = this.state.left_side_bar;
        let item_i = 0;
        let item_i_found = 0;
        let report_name_header = this.state.report_name_header;
        for (item_i in left_side_bar){
            if (left_side_bar[item_i].id == id){
                left_side_bar[item_i].selected = true;
                item_i_found = item_i;
                report_name_header = left_side_bar[item_i].name;
            } else {
                left_side_bar[item_i].selected = false
            }
        }
        if (this.state.is_mobile){
            this.onTopBarOpenerClick(null);
        }
        this.setState(state => ({
            ...state, 
            left_side_bar: left_side_bar,
            selected_index: item_i_found,
            report_name_header: report_name_header,
        }));
        this.get_report(id, item_i_found);
    }

    get_report = (id, index) => {
        let report_data = this.state.left_side_bar[index];
        console.log("report_data: ", report_data);
        // report_image
        let image_url = this.image_url_prefix + this.run_id + "/" + report_data.report_image;
        this.setState(state => ({
            ...state, 
            image_url: image_url
        }));
    }

    get_click_data = async (x, y) => {
        try {
            let report_data = this.state.left_side_bar[this.state.selected_index];
            const response = await fetchModule.doGet({path: '/reports/click_data/' + this.run_id + "/" + report_data.report_filer + ".json?x=" + x + "&y=" + y});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("JSON: ", json);

                let info_to_render = this.info_to_render_placeholder;
                let bottom_close_bar_visible = false;
                if (!("error" in json)) {
                    console.log('no error');
                    let info_data = []
                    // info_data.push({
                    //     "key": "click_coords",
                    //     "data": "x: " + x +" y: " + y
                    // })
                    for (let key in json){
                        if (key == "rect"){
                            info_data.push({
                                "key": key,
                                "field_name": json[key]["field_name"],
                                "post_field_value": json[key]["post_field_value"],
                                "data": "[" + json[key]["value"][0] + ", " + json[key]["value"][1] + ", " + json[key]["value"][2] + ", " + json[key]["value"][3] + "]"
                            })
                        } else {
                            info_data.push({
                                "key": key,
                                "field_name": json[key]["field_name"],
                                "post_field_value": json[key]["post_field_value"],
                                "data": json[key]["value"]
                            })
                        }
                        
                    }
                    bottom_close_bar_visible = true;
                    info_to_render = <div>
                        <p>Координаты клика:</p>
                        <p>{"x: " + x +" y: " + y}</p>
                        <hr></hr>
                        {
                            info_data.map((object, i) => {
                                return <p key={"info_to_render_key_" + i}>{object.field_name}: {object.data}{object.post_field_value}</p>;
                            })
                        }
                    </div>                    
                }
                
                this.setState(state => ({
                    ...state, 
                    report_data: json,
                    info_to_render: info_to_render,
                    bottom_close_bar_visible: bottom_close_bar_visible
                }));
                return json;
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                return json;
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
    }

    onScreenClick = (report_id, coords) => {
        console.log("onScreenClick: ", report_id, coords);
        this.get_click_data(coords[0], coords[1]);
        // здесь запрос в бек, за данными из info по координате
    }

    onBottomBarCloseClick = (e) => {
        let info_to_render = this.info_to_render_placeholder;
        let bottom_close_bar_visible = false;
        this.setState(state => ({
            ...state, 
            info_to_render: info_to_render,
            bottom_close_bar_visible: bottom_close_bar_visible
        }));
    } 

    onTopBarOpenerClick = (e) => {
        let top_close_bar_visible = this.state.top_close_bar_visible;
        top_close_bar_visible = !top_close_bar_visible;
        this.setState(state => ({
            ...state, 
            top_close_bar_visible: top_close_bar_visible,
        }));
    }
    

    
  
    render = () => {
      return (
            <>
            {this.state.is_mobile 
            ? 
            <div className={this.not_use_header ? "Report-mobile-demo" : "Report-mobile"}>
                
                <div className="Report-mobile-topbar">
                    <div>
                        <div className="Report-mobile-topbar-header" onClick={this.onTopBarOpenerClick}>
                            <a href="/">
                                <h3 className="Report-mobile-topbar-header-element">UXtool</h3>
                            </a>
                            <h4 className="Report-mobile-topbar-header-element">Выбор отчета</h4>
                            <div className="Report-mobile-topbar-header-element_horizontal">
                                <p className="Report-mobile-topbar-header-element">{this.state.report_name_header}</p>
                                {this.state.top_close_bar_visible
                                ? <img className="dropdown_icon" src={dropdown_icon_up}/>
                                : <img className="dropdown_icon" src={dropdown_icon}/>
                                }
                                
                            </div>
                            
                            <h3 
                                className="Report-mobile-topbar-header-element" 
                                id="Topbar-collapsable-opener"
                            ></h3>
                        </div>
                        
                        {this.state.top_close_bar_visible
                        ?
                        <div className="Report-mobile-topbar-collabsable" id="Report-mobile-topbar-collabsable">
                            <div>
                            {
                                this.state.left_side_bar.map((object, i) => {
                                    return <SidebarElement sidebar_prefix={"mobile-topbar"} text={object.name} id={object.id} selected={object.selected} onClick={this.onLeftSideBarClick}></SidebarElement>
                                    ;
                                })
                            }
                            </div>
                        </div>
                        :
                        <></>
                        }
                        
                    </div>
                </div>
              <div className="Report-mobile-center" id="Report-center">
                <ScreenDrawer id={"screen_drawer"} image_source={this.state.image_url} onClick={this.onScreenClick}/>
              </div>
              
              <div className="Report-mobile-bottombar_stub" hidden={this.not_use_header}>
                <div className="Report-mobile-bottombar-stub-header">
                    <h3>Инфо</h3>
                    <p>Нажмите на изображение, чтобы получить данные</p>
                </div>
              </div>
              
              <div className="Report-mobile-bottombar" hidden={this.not_use_header}>
                <div className="Report-mobile-bottombar-header">
                    <h3>Инфо</h3>
                    {
                        this.state.bottom_close_bar_visible
                        ? <></>
                        : <>{this.state.info_to_render}</>
                    }
                    {this.state.bottom_close_bar_visible
                    ? <div onClick={this.onBottomBarCloseClick}><img className="close_icon" src={close_icon}/></div>
                    : <></>}
                    
                </div>
                <div className="Report-mobile-bottombar-element">
                    {
                        this.state.bottom_close_bar_visible
                        ? 
                        <div id="infobar_empty_placeholder">
                            {this.state.info_to_render}
                        </div>
                        : <></>
                    }
                    
                </div>
              </div>
            </div>
            :
            <>
            
            {
                this.not_use_header ? 
                <></>
                :

                <div className="Report-mobile-topbar-header" onClick={this.onTopBarOpenerClick}>
                    <a href="/">
                        <h2 className="Report-mobile-topbar-header-element">UXtool</h2>
                    </a>
                    <a href="/">
                        <h3 className="Report-mobile-topbar-header-element">Назад к отчетам</h3>
                    </a>
                    
                </div>
                
            }

            <div className={this.not_use_header ? "Report-demo" : "Report"}>

            <div className="Report-sidebar">
            <div className="Report-sidebar-element">
                <h1>Выбор отчета</h1>
            </div>

            <div>
                {
                    this.state.left_side_bar.map((object, i) => {
                        return <SidebarElement text={object.name} id={object.id} selected={object.selected} onClick={this.onLeftSideBarClick}></SidebarElement>
                        ;
                    })
                }
            </div>

            </div>
            <div className="Report-center" id="Report-center">
            <ScreenDrawer id={"screen_drawer"} image_source={this.state.image_url} onClick={this.onScreenClick}/>
            </div>
            <div className="Report-sidebar">
            <div>
                <h1>Инфо</h1>
            </div>
            <div className="Report-sidebar-element">
                <div id="infobar_empty_placeholder">
                    {this.state.info_to_render}
                </div>
            </div>
            </div>
            </div>
            </>
            
            
            }
            </>
            
        );
      }
  }
  
  export default Report;